.plenariaEditar > input:nth-child(2){
  width: 97%;
  margin-right: 1%;
}

.formularioGeral {
  min-height: 190px;
}

.espaco2LadosB_Plenaria {
  margin-top: 0.55rem;
}

.cadastroDocInternoPlenaria {
  min-width: auto;
}