.DoseAplicadaEditar > input:nth-child(2), .DoseAplicadaEditar > select{
  width: 47.5%;
  margin-right: 1%;
}

.DoseAplicadaEditar > select:nth-child(5){
  width: 97%;
  margin-right: 1%;
}

.formularioGeral {
  min-height: 190px;
}