.manifestacaoEditar > input:nth-child(2), .manifestacaoEditar > input:nth-child(6)
{
  width: 47.5%;
  margin-right: 1%;
}

.formularioGeral {
  min-height: 190px;
}

.espaco2LadosB_Plenaria {
  margin-top: 0.55rem;
}

.anexosManifestacao > div > p {
  margin-left: 0px;
  margin-top: 0px;
  text-overflow: ellipsis;
  padding-bottom: 10px;
}


.anexosManifestacao > div > a {
  cursor: pointer;
  flex-direction: column;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 0px;
  
  position: relative;
  display: flex;
  float: left;

  width: 50%;
  height: 30px;  
  background-color: var(--mainColor);
  color: var(--textColor2);
  border-radius: 10px 10px 10px 10px;  
}

.anexosManifestacao > div{
  height: fit-content;
  overflow-y: hidden;  
  padding-bottom: 10px;
}

.cadastroDocInternoManifes{

  margin-bottom: 35px;

}


.cadastroDocInternoManifes p{
  display: flex;
  position: relative;

  margin-left: 10px;
  margin-right: 10px;
  
  font-size: 20px;
  line-height: 1.2;
  color: var(--textColor);
  text-align: left;

  top: 20px;
  bottom: 10px;
  left: 5px;

  min-height: fit-content;

}


.PerguntaForm {
  border: transparent;
  margin-bottom: -20px;
}

/* colocar linha grande*/  
.PerguntaForm > input:nth-child(2){
  width: 97%;
  margin-left: 1.5%;
}

.PerguntaForm > textarea{
  height: 10%;
  min-width: 97%;
  max-width: 97%;
  width: 97%;
  border-radius: 0px;
}

.manifestacaoEditar > select
{
  width: 47.5%;
  margin-right: 1%;
}