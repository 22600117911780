/* Ajustar espaços itens lado direito (pag noticia)*/  
.espaco2LadosB_Link {
    margin-top: 2.12rem;
}

/* 
@media (max-width: 830px) {
.espaco2LadosB {
  margin-top: 0%;
}  
}
*/  

.linkEditar >input:nth-child(6)  {
  width: 97%;
}