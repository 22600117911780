.espaco2LadosB_Usuário {
    margin-top: 0.55rem;
}
  
/* parte de upload*/  
.cadastroSenhaUsuario {
  width: 100%;  
  min-width: 50px;

  height: fit-content;
  padding-bottom: 0px;
  margin-top: 10px;
  background-color: var(--mainColor2);
  border-radius: 15px 15px 15px 15px;
  cursor: default;
  color: var(--textColor);
  border: var(--textColor) 0.8px;
  border-style:solid; 
}

.cadastroSenhaUsuario p , .cadastroSenhaUsuario  span{
  display: flex;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;

  font-size: 20px;  
  line-height: 1.2;
  color: var(--textColor);
  text-align: left;
  top: 20px;
  left: 5px;
}

.cadastroSenhaUsuario span {
  margin-top: 5px;
  margin-bottom: 0px;
}

.senhaUsuario > div > div > div {
  min-height: 100px;
}

.senhaUsuario  input:nth-child(2){
  width: 47.5%;
  margin-right: 1%;
}

