  
.modal-content {
    background-color: transparent;
    padding: 0px;
    max-width: 85%;
    margin: 0 auto;
    height: auto;
    text-align: center;
}
   

.modal-content h1{
    text-transform: uppercase;    
    font-family: var(--fontA);
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 2rem;
   
}

.modal-content .table th,
.table td {
    text-align: left;
}

.table td {
    font-size: 1rem;
}

 
.modal-content .table th {
    background-color: var(--mainColor);
    color: var(--textColor2);
    font-family: var(--fontA);
    font-size: 1.3rem;
    font-weight: 600;
}

.modal-content .table td {
    font-family: var(--fontA);
    font-size: 20px;
}

.tabelaConcurso tr{
    height: 1rem;
}

.modal-content .table tbody tr:nth-child(even) {
    background-color: var(--mainColor2);
}

.modal-content .table tbody tr:hover {
    background-color: var(--mainColor4);
}

.modal-content button {
    font-family: var(--fontA);
    font-size: 20px;
    background: transparent;
    color: var(--mainColor);

    border: none;
    cursor: pointer;
}

