.fundoLogin{
  height: 60rem;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login{
  padding: 50px;
  color: var(--textColor);
  text-align: center;
}

.login > h1{
  margin-top: 30px;
  margin-left: 8%;
  margin-right: 8%;  
  font-weight: 400;
  font-size: 22px;
}

.login > form {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  gap: 15px;
  margin-top: 8rem;
}

.login > form > input{
  height: 40px;
  width: 80%;
  max-width: 350px;
  display:flexbox;
}

.login > form > input::placeholder {
  font-size: 20px;
  opacity: 0.5;
}

.login > form > input, select, textarea{
  font-size: 20px;
}

.login > form > button{
  height: 40px;
  width: 80%;
  max-width: 350px;
  display:flexbox;
  font-size: 22px;
  color: var(--textColor);
}