
/* Substituindo do GERAL*/  
.municipio > input:nth-child(2), .formularioGeral > input:nth-child(3){
  width: 47.5%;
  margin-right: 1%;
}
.municipio > input{
  width: 47.5%;
  margin-right: 1%;
}
.municipio > select {
  width: 47.5%;
  margin-right: 1%;
}

.formMunicipio {
  border-bottom: var(--textColor) 0.8px solid;
  border-radius: 15px 15px 15px 15px;
  margin-bottom: 0px;
  margin-top: 0px;
}