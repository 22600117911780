.espaco2LadosB_Obra {
    margin-top: 1.8rem;
  }

  .obraEtapa > textarea{
    height: 50px;
    width: 97%;
    top: 0px;
    position: relative;
    background-color: var(--textColor2);
    border-color: var(--mainColor2);
    border: 1px solid;
    margin-bottom: 0px;
    font-size: 20px;
    color: var(--textColor);
    padding-left: 10px;
  }

  .etapas > div > div > button{
    width: 100px;
    height: 40px;  
  
    cursor:pointer;
    display: inline;

    margin: -10px 0px 10px 0px;
  }