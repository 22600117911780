.IconeEditar > input:nth-child(2), .IconeEditar > input:nth-child(4), .IconeEditar > select{
  width: 47.5%;
  margin-right: 1%;
}

.formularioGeral {
  min-height: 190px;
}

.IconeEditar  img{
  margin-top: 30px;
  height: 130px;

}