.logoff {
    font-size: 22px;
          
    margin-left: 8%;
    margin-right: 8%;
    margin-bottom: 20px;
    font-size: 32px;
    color: var(--textColor2);
    background-color: var(--mainColor);
    max-width: fit-content;
    padding: 6px 30px;
    border-radius: 30px 10px;
    cursor: pointer;

    float: right;
    margin-top: -80px;
  }
