
.formularioContato {
  margin-top: 0px;
}

.formContato3{
  border-radius: 15px 15px 15px 15px;
  margin-bottom: 10px;
  border-bottom: solid var(--textColor) 0.8px;
  margin-top: 0px;
}

.formContato{
  border-radius: 15px 15px 15px 15px;
  margin-bottom: 10px;
  border-bottom: solid var(--textColor) 0.8px;
  margin-top: 0px;
}

.formContato2{
  border-radius: 15px 15px 15px 15px;
  margin-bottom: 10px;
  border-bottom: solid var(--textColor) 0.8px;
  margin-top: 0px;
}

/* segundo input*/  
.formContato > input:nth-child(2), .formContato > input:nth-child(3){
  width: 47.5%;
  margin-right: 1%;
}

.formContato2 > input:nth-child(2), .formContato2 > input:nth-child(3){
  width: 47.5%;
  margin-right: 1%;
}
.formContato2 > input:nth-child(5), .formContato2 > input:nth-child(6){
  width: 96%;
  margin-right: 1%;
}

/* segundo input*/  
.formContato3 > input:nth-child(2), .formContato3 > input:nth-child(3){
  width: 47.5%;
  margin-right: 1%;
}
