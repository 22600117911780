.espaco2LadosB_Docs {
    margin-top: 0.55rem;
  }

.entidadeEndereco input{
  height: 50px;
  width: 97%;
  top: 30px;
  position: relative;
  background-color: var(--textColor2);
  border-color: var(--mainColor2);
  border: 1px solid;
  margin-bottom: 25px;
  border-radius: 15px 15px 15px 15px;
  font-size: 20px;
  color: var(--textColor);
  padding-left: 10px;
}
  
.entidade {
  min-width:auto;
}