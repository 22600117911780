

.PerguntaForm {
  border: var(--textColor) 0.8px;
  border-style:solid; 
  border-radius: 15px 15px 15px 15px;
}

/* colocar linha grande*/  
.PerguntaForm > input:nth-child(2){
  width: 97%;
  margin-left: 1.5%;
}

.PerguntaForm > textarea{
  height: 10%;
  min-width: 97%;
  max-width: 97%;
  width: 97%;
  border-radius: 0px;
}
