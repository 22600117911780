 .manifesAdmin input[type=file]{
    height: 80px;
    width: 97%;
    top: 30px;
    position: relative;
    background-color: var(--textColor2);
    border-color: var(--mainColor);
    color:var(--mainColor);
    border: 1px solid;
    margin-bottom: 25px;
    border-radius: 15px 15px 15px 15px;
    font-size: 16px;
    font-family: var(--fontA);
    padding-left: 10px;
  }

  .manifesAdmin li{
    color:var(--mainColor);
  }