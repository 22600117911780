@font-face {
  font-family: "Reem Kufi Regular";
  src: url("../Geral/Reem\ Kufi\ Regular.ttf");
    /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */  
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: var(--fontA);
}

:root {
	--mainColor: #004348;
	--mainColor2: #D9D9D9;  
  --mainColor3: #b6a6a6;
  --mainColor4: #24977B;

  --textColor: #004348;
	--textColor2: white;
  --textColor3: black;

  --fontA: 'Reem Kufi Regular';  
  --fontB: 'Inter';
}

body {
  font-size: 22px;
  font-weight: 200;
  overflow-x: hidden;
}

a{
  color: inherit;
}

.tituloPagina {
  font-size: 22px;
        
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 20px;
  font-size: 32px;
  color: var(--textColor2);
  background-color: var(--mainColor);
  max-width: fit-content;
  padding: 6px 30px;
  border-radius: 30px 10px;
  
}

/* Menus ABAS*/ 
.menuPadrao  {
  padding-top: 20px;
  width: 100%;
  height: fit-content;
  /* 
  width: 98%;
  margin-left: 1%; 
  margin-right: 1%; 
  */  
  /* esta é a linha embaixo dos botoes */  
  background-color: transparent;
  /* reduzir espçamento button */  
  font-size: 0;
  text-align: center;  
  /* espaço no final menu */ 
  overflow-x: auto;
}

.menuPadrao li button {
  top: 0px;
  position: relative;
  width: fit-content;
  padding-right: 2%;
  padding-left: 2%;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  cursor: pointer;
}

.menuPadrao  li button:hover{
  background-color: var(--mainColor);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.semLinha{
  margin-bottom: -15px;
}

.linha{  
  border-bottom: var(--mainColor2) 5px solid;
}

/* Menus ABAS*/ 

/* tela de espera*/ 
.MuiBackdrop-root p, .MuiBackdrop-root span{
  text-align: center;
  margin-top: -60vh;
  margin-right: 15px;
  color: white;
  font-size: 35px;
}

/* react alert */ 
.MuiAlert-root{
  margin-left: 25%;
  margin-right: 25%;
}
.MuiAlert-message{
  color: var(--textColor4);
  font-size: 20px;  
  line-height: 1.2;
  margin-top: 3px;
}
.MuiAlert-icon{
  font-size: 1.6rem;
  color: var(--textColor4);
}

/* Icones da tabela */ 
.MuiTableCell-body a{
  font-size: 1.7rem;
}
.MuiTableCell-body:nth-child(5) a{
  font-size: 1.7rem;
  color: var(--mainColor);
  cursor: pointer;
}
.MuiTableCell-body:nth-child(6) a{
  font-size: 1.7rem;
  color: var(--mainColor);
  cursor: pointer;
}
.tabelaPadrao input {
  width: 18px;
  height: 18px;
}

/* Limite de tamanho imagens dentro da tabela */ 
.MuiTableCell-root > img{
  /* width: 50px;*/ 
  height: 35px;
}
/* Limite de tamanho dos icones dentro da tabela */ 
.limitaIcone img{
  width: fit-content;
}

/* Botão novo */ 
.optionNovo button {
  top: -50px;
  min-width: fit-content;
  padding:10px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 15px 15px 15px 15px;
}
.optionNovo button:hover{
  background-color: var(--mainColor);
  border-color: var(--mainColor2);
  color: var(--textColor2);
}
.optionNovo {
  margin-left: 8%;
  margin-right: 14%;
  display: flex;
  width: fit-content;
  overflow-x: auto;
  overflow-y: hidden;
}
@media (max-width: 1290px) {
  .optionNovo {
    margin-right: 10vw;
  }  
}

/* Botão opções */ 
.optionsBotoes{
  margin-left: 14%;
  margin-right: 8%;
  margin-top: -60px;

  position: relative;
  display: flex;
  float: right;
  width: auto;  
  overflow-x: auto;
}

@media (max-width: 479px) {
  .optionsBotoes {
    width: 220px;
    overflow-x: auto;
  }  
}

.optionsBotoes button {
  top: -50px;
  min-width: fit-content;
  padding:10px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 15px 15px 15px 15px;
  margin-left:5px;
}
.optionsBotoes button:hover{
  background-color: var(--mainColor);
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.loading  {
  margin-left: 8%;
  margin-right: 8%;  
  margin-top: 2%;
  margin-bottom: 40px;
  text-align: center;
  /*  pra não aparecer a virgula  */ 
  color: var(--mainColor2);  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
}

/* botão de não tem nada */ 
.loading  > div > button{
  width: fit-content;
  height: 50px;  

  padding-right: 5px;
  padding-left: 5px;

  cursor:pointer;
  font-size: 20px;
  font-weight: 100;
  color: var(--mainColor);

  margin: 20px 0px 5px 0px;
  background-color: var(--mainColor);
  color: var(--textColor2);
  border-radius: 15px 15px 15px 15px;
  border: var(--textColor) 0.8px;
  border-style:solid; 
}

/* Cor botões status */ 
.true{
  color: var(--mainColor);
}
.false{
  color: var(--mainColor3);  
}

/* FORMULARIOS */ 

.formularioGeral {
  width: 100%;  
  min-width: 250px;
  min-height: 230px;  
  height: fit-content;
  padding-bottom: 15px;

  margin-top: 10px;
  background-color: var(--mainColor2);
  cursor: default;
  border: var(--textColor) 0.8px;
  border-style:solid; 

  border-bottom: transparent;
  border-radius: 15px 15px 0px 0px;
}

.formularioGeral > button{
  width: 97%;
  height: 50px;  

  cursor:pointer;
  font-size: 20px;
  font-weight: 100;
  color: var(--mainColor);

  margin: 20px 0px 5px 0px;
  background-color: var(--mainColor);
  color: var(--textColor2);
  border-radius: 15px 15px 15px 15px;
  border: var(--textColor) 0.8px;
  border-style:solid; 
}

.formularioGeral > p , .formularioGeral > label, .formularioGeral > span {
  position: relative;
  margin-left: 15px;
  margin-right: 10px;

  font-size: 20px;
  
  line-height: 1.2;
  color: var(--textColor);
  /*
  text-shadow: 0.5px 0.5px 0.5px rgb(170, 160, 160);
  */ 
  text-align: left;
  top: 20px;
}

.formularioGeral > input, .formularioGeral > select, .formularioGeral > textarea{
  height: 50px;
  width: 97%;
  top: 30px;
  position: relative;
  background-color: var(--textColor2);
  border-color: var(--mainColor2);
  border: 1px solid;
  margin-bottom: 25px;
  border-radius: 15px 15px 15px 15px;
  font-size: 20px;
  color: var(--textColor);
  padding-left: 10px;
}

.formularioGeral > input::placeholder {
  font-size: 20px;
  opacity: 0.5;
}

/* primeiros inputs*/  
.formularioGeral > input:nth-child(2){
  width: 47.5%;
  margin-right: 50%;
}
/* corrigido pag cadastro*/  
.formularioGeral > input:nth-child(5), .formularioGeral >input:nth-child(6){
  width: 47.5%;
  margin-right: 1%;
}

/* radio button*/  
.statusRadio {
  width: 47.5%;
  margin-left: 50%;
  margin-top: -35px;
  color: var(--textColor);
  /*
    border: var(--textColor) 0.8px;
    border-style:solid; 
  */  
}
.statusRadio > span {
  padding: 10px;
  font-size: 20px;
  top: 5px;
  margin-left: 5px;
  display: flex;
  
}
.statusRadio > input {
  margin-left: 5%;
  margin-right: 5px;
  position: relative;
}
.statusRadio > input[type=radio] {
    border: 0px;
    width: 20px;
    height: 20px;
}
.formularioGeral  > label {
  display: flex;
}
input[type='radio']:nth-child(1):checked:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: 0.2px;
  left: 0.7px;
  position: relative;
  background-color: var(--mainColor);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
input[type='radio']:nth-child(3):checked:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: 0.2px;
  left: 0.7px;
  position: relative;
  background-color: var(--mainColor2);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
input[type='radio']:nth-child(2):checked:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: 0.2px;
  left: 0.7px;
  position: relative;
  background-color: var(--mainColor);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
input[type='radio']:nth-child(4):checked:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: 0.2px;
  left: 0.7px;
  position: relative;
  background-color: var(--mainColor2);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
input[type='radio']:nth-child(7):checked:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: 0.2px;
  left: 0.7px;
  position: relative;
  background-color: var(--mainColor);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
input[type='radio']:nth-child(9):checked:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: 0.2px;
  left: 0.7px;
  position: relative;
  background-color: var(--mainColor2);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
/* radio button fim*/  

/* radio metade da tela */  
.novoRadio {
  width:  97%;
  margin-left: 0%;
  margin-top: 5px;
  color: var(--textColor);
}
.novoRadio > input, .novoRadio > label{
  margin-top: 30px;
}

.novoRadio > div> label{
  margin: 15px;
}

.formulario {
  margin-left: 8%;
  margin-right: 8%;  
  margin-top: 5px;
  text-align: center;
  /*  pra não aparecer a virgula  */ 
  color: var(--mainColor2);  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: column;
}

.formularioConteudo {
  width: 100%;  
  min-width: 250px;
  min-height: 450px;  
  height: 450px;

  margin: 0px;
  background-color: var(--mainColor2);
  cursor: default;
  border: var(--textColor) 0.8px;
  border-style:solid; 
  color: var(--textColor);

  border-top: transparent;
  margin-top: -10px;
  border-radius: 0px 0px 15px 15px;
}

.formularioConteudo > span {
  padding: 9px;
  font-size: 20px;
  top: 5px;
  margin-left: 5px;
  display: flex;
}


.formularioConteudo > button{
  width: 100%;
  height: 50px;  

  cursor:pointer;
  font-size: 20px;
  font-weight: 100;
  color: var(--mainColor);

  margin: 20px 0px 20px 0px;
  background-color: var(--mainColor);
  color: var(--textColor2);
  border-radius: 15px 15px 15px 15px;
  border: var(--textColor) 0.8px;
  border-style:solid; 
}

/* FORMULARIOS fim*/ 

.botaoExcluirEspec button {
  margin-left: 8%;
  margin-bottom: 0.1%;
  padding: 5px 20px;
  min-width: 170px;
  height: 50px; 

  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 15px 15px 15px 15px;
}

.botaoExcluirEspec button:hover{
  background-color: var(--mainColor);
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

/* Titulo concurso*/  
.formularioGeralTitulo {
  display: flex;
  position: relative;
}
.formularioGeralTitulo > p{
  width: 47.5%;
  display: flex;
  position: relative;
  margin-left: 15px;
  margin-right: 10px;
  font-size: 20px;  
  line-height: 1.2;
  color: var(--textColor);
  text-align: left;
  top: 20px;
}

/* parte de baixo do fomulario redondo*/  
.formRedondo {
  border-bottom: var(--textColor) 0.8px solid;
  border-radius: 15px 15px 15px 15px;
  margin-bottom: 50px;
}

/* parte de upload*/  
.cadastroDocInterno {
  width: 100%;  
  min-width: 250px;

  height: fit-content;
  padding-bottom: 0px;
  margin-top: 10px;
  background-color: var(--mainColor2);
  border-radius: 15px 15px 15px 15px;
  cursor: default;
  color: var(--textColor);
  border: var(--textColor) 0.8px;
  border-style:solid; 
}

.cadastroDocInterno p , .cadastroDocInterno  span{
  display: flex;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;

  font-size: 20px;  
  line-height: 1.2;
  color: var(--textColor);
  text-align: left;
  top: 20px;
  left: 5px;
}

.cadastroDocInterno span {
  margin-top: 5px;
  margin-bottom: 0px;
}


.cadastroDocInterno  input[type=file]{
  height: 80px;
  width: 97%;
  top: 30px;
  position: relative;
  background-color: var(--textColor2);
  border-color: var(--mainColor2);
  border: 1px solid;
  margin-bottom: 25px;
  border-radius: 15px 15px 15px 15px;
  font-size: 16px;
  font-family: var(--fontA);
  padding-left: 10px;
}


.cadastroDoc {
  margin-left: 8%;
  margin-right: 8%;  
  margin-top: -10px;
  text-align: center;
  /*  pra não aparecer a virgula  */ 
  color: var(--mainColor2);  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: column;

  margin-top: 0px;
}

.cadastroDoc button{
  width: 97%;
  height: 50px;  

  cursor:pointer;
  font-size: 20px;
  font-weight: 100;
  background-color: var(--mainColor);
  color: var(--textColor2);

  margin: 20px 0px 20px 0px;
  border-radius: 15px 15px 15px 15px;
  border: var(--textColor) 0.8px;
  border-style:solid; 
}

/* Anexos já carregados*/  
.anexos {
  margin-top: 30px;
  color: var(--mainColor2);  
  height: fit-content;
}
.anexos > div{
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: -2px;
  min-height: 40px;
  border: var(--textColor) solid 2px;
  overflow-x: auto;
}
.anexos > div > img{
  object-fit:fill;
  float: left;
  width: 80px;
  height: 65px;
}
.anexos > div > p{
  margin-left: 0px;
  margin-top: -12px;
  text-overflow:ellipsis;
}
.anexos > div > a {
  cursor: pointer;
  flex-direction: column;
  margin-left: 5px;
  margin-top: -10px;
  
  position: relative;
  display: flex;
  float: right;

  width: 80px;
  height: 30px;  
  background-color: var(--mainColor);
  color: var(--textColor2);
  border-radius: 10px 10px 10px 10px;  
}

/* Alert dentro do upload*/  
.alertUpload .MuiAlert-root{
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 20px;
  margin-bottom: -15px;
}
.alertUpload .MuiAlert-message{
  color: var(--textColor4);
  font-size: 18px;  
  line-height: 1.2;
  margin-top: 3px;
}
.alertUpload .MuiAlert-icon{
  font-size: 1.6rem;
  color: var(--textColor4);
}
.alertUpload button {
  margin: -10px;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
/* Deixar branco tela de espera do upload*/  
.uploadWait > div > p{
  font-size: 35px;
  color: var(--textColor4);
}
.uploadWait > div > span{
  font-size: 35px;
  color: var(--textColor4);
  top: -270px;
}

/* upload de Imagem galeria*/  
.uploadImagem > li > p{
  left: -10px;
}
.uploadImagem{
  display: flex;
  margin-left: 2%;
  margin-top: 20px;
  margin-bottom: 0px;
  /*
  border: black;
  border-style:solid; 
  */   
  overflow-x: auto;
  overflow-y: hidden;
}
.uploadImagem > li{
  height: 200px;
}
.uploadImagem > li > img{
  
  width: 160px;
  height: 140px;
  margin-right: 5px;  
  margin-bottom: -20px;
  object-fit: cover;
}
.uploadImagem > li > p{
  display: flex;
  position: relative;
  right: 10px;
}

/* Alinhamentos direita e esquerda para paginas com upload de imagem*/  
.alinhaDireita{
  width: 50%;
  float: right;
}
.alinhaDireita > input[type=file]{
  top: 26px;
  position: relative;
  background-color: var(--textColor2);
  border-color: var(--mainColor2);
  border: 1px solid;
  margin-bottom: 25px;
  border-radius: 15px 15px 15px 15px;
  font-size: 16px;
  font-family: var(--fontA);
  color: var(--textColor);
  padding-left: 10px;
}
.alinhaDireita > input, .alinhaDireita > select, .alinhaDireita > textarea{
  height: 50px;
  width: 97%;
  top: 30px;
  position: relative;
  background-color: var(--textColor2);
  border-color: var(--mainColor2);
  border: 1px solid;
  margin-bottom: 25px;
  border-radius: 15px 15px 15px 15px;
  font-size: 20px;
  color: var(--textColor);
  padding-left: 10px;
}
.alinhaEsquerda{
  width: 50%;
  height: 100%;
}
.alinhaEsquerda > img{
  width: 100%;
  height: 100%;
  max-height: 400px;
  object-fit:contain;
    /* Mudado por causa da pag pessoa
  object-fit:cover;
  */ 
  margin-top: 10px;
  padding: 10px;
  border-radius: 20px;
}
.alinhaEsquerda > input[type=file]{
  top: 26px;
  position: relative;
  background-color: var(--textColor2);
  border-color: var(--mainColor2);
  border: 1px solid;
  margin-bottom: 25px;
  border-radius: 15px 15px 15px 15px;
  font-size: 16px;
  font-family: var(--fontA);
  color: var(--textColor);
  padding-left: 10px;
}
.alinhaEsquerda > input, .alinhaEsquerda > select, .alinhaEsquerda > textarea{
  height: 50px;
  width: 97%;
  top: 30px;
  position: relative;
  background-color: var(--textColor2);
  border-color: var(--mainColor2);
  border: 1px solid;
  margin-bottom: 25px;
  border-radius: 15px 15px 15px 15px;
  font-size: 20px;
  color: var(--textColor);
  padding-left: 10px;
}

/* Ajustar div para 100% , dar espaço ou quebra de linha*/  
.bloqueiaDiv {
  width: 97%;
}
.espaco1Lado {
  height: fit-content;
  min-height: 440px;
}

/* Ajustar MENU admin*/  
.sc-fsYfdN.iJWUfq {
  overflow: auto;
}
.sc-jRQBzY.kiwNaw{
  width: 270px;
  height: 100vh;
}

.removeBorda {
  border-style:none; 
}